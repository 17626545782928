import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Nav from './Nav';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Auth0Provider domain="dev-07ysvziixk2iozay.us.auth0.com" clientId="W2IrBB9fers7652acqYo0LvyMJwblKhE"
            authorizationParams={
                {
                    redirect_uri: window.location.origin,
                    audience: "https://dev-07ysvziixk2iozay.us.auth0.com/api/v2/",
                    scope: "profile email update:current_user_metadata"
                }
            }>
            <Nav />
            <App />
        </Auth0Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
