import React from "react";
import { UserIcon } from "@heroicons/react/20/solid";
import { useAuth0 } from "@auth0/auth0-react";

const NavMenu = () => {
    const { user, logout } = useAuth0();

    return (
        <div className="dropdown dropdown-end">
            <label tabIndex={0}
                className="btn btn-ghost btn-circle avatar">
                <div className="w-10 rounded-full">
                    {
                        user?.picture ? <img src={user.picture} alt={user.name} className="rounded-full" /> : <UserIcon className="h-10 w-10 text-gray-500" />
                    }
                </div>
            </label>
            <ul tabIndex={0}
                className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-200 rounded-box w-52">
                <li> {user?.name ?? user?.email ?? "UNKNOWN"} </li>
                <li>
                    <button className="justify-between">
                        Profile
                    </button>
                </li>
                <li>
                    <button>Settings</button>
                </li>
                <li>
                    <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                        Log Out
                    </button>
                </li>
            </ul>
        </div>
    );
}

const Nav = () => {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

    return (
        <nav className="navbar bg-base-200">
            <div className="flex-1">
                <a href="https://www.DeployPulse.com/" className="btn btn-ghost normal-case text-xl">
                    <img src="DeployPulse_Logo.svg" className="h-8 mr-3" alt="Deployo Pulse Logo" />
                    <span className="self-center text-2xl whitespace-nowrap font-logo">Deploy Pulse</span>
                </a>
            </div>
            <div className="flex-none gap-2">
                {
                    isLoading ? null : isAuthenticated ? <NavMenu /> : <button className="btn btn-ghost" onClick={() => loginWithRedirect()}>Log In</button>
                } </div>
        </nav>
    );
}

export default Nav;
